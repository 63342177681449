import React from "react"
import { graphql } from "gatsby"
import ImageGallery from "react-image-gallery";

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockContent from "../components/blockcontent"
import t from "../utils/translate"
import { home } from "../utils/image"

import styles from "./press.module.css"
import "./imagegallery.css";

export const query = graphql`
  query($id: String!) {
    sanityPress(id: { eq: $id }) {
      id
      title {
        ...Locales
      }
      slug {
        current
      }
      seo {
        ...AllSeo
      }
      id
      _rawGallery
      _rawContent
    }
  }
`

const PressPage = ({ data, pageContext: { locale, strings } }) => {
  const press = data.sanityPress

  if (!press) return (
    <Layout locale={locale}>
      error
    </Layout>
  )

  const images = [];

  press && press._rawGallery.map(image => {
    return images.push({
      original: home(image, 1600)
    });
  });

  return (
    <Layout locale={locale}>
      <Seo locale={locale} seofield={press.seo}></Seo>
      <div className={styles.pressContainer}>
        <div key="back" className={styles.article}>
          <a href="/news">{t(strings.back, locale)}</a>
          <br /><br />
        </div>
        {images && (
          <ImageGallery items={images} showThumbnails={false} showPlayButton={false} />
        )}
      </div>
      <div className={styles.pressInfoContainer}>
        <h3 className={styles.h3}>
          {t(press.title, locale)}
        </h3>
        <article className={styles.article}>
          {t(press._rawContent, locale) && (
            <BlockContent
              blocks={t(press._rawContent, locale)}
            ></BlockContent>
          )}
        </article>
      </div>
    </Layout>
  )
}

export default PressPage
